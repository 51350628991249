<template>
  <div class="row">
    <div class="col-md-12" v-if="Object.keys(user).length !== 0">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="user_accessories" class="col-form-label col-form-label-sm">Accessories <span class="custom-required">*</span></label>
                <v-select v-model="user_accessories" multiple :class="errors.first('user_accessories') ? 'borderColor' : ''" name="user_accessories" id="user_accessories" v-validate="'required'" label="name" :options="admin_scenario_accessories" placeholder="Select a accessory"></v-select>
                <div v-if="errors.first('user_accessories')" class="error">Accessories is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <a-button type="primary" class="text-center mr-2" :loading="loader" @click.prevent="save">Assign Accessories</a-button>
                <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="text-center text-secondary">{{ message }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'accessory_assign_to_user',
  data() {
    return {
      user: {},
      admin_scenario_accessories: [],
      user_accessories: [],
      validation_errors: {},
      show: false,
      loader: false,
      message: null,
    }
  },
  mounted() {
    this.getUserAccessories()
  },
  methods: {
    getUserAccessories() {
      const userId = this.$route.params.user_id
      apiClient.get('api/admin-scenario/accessory-users/' + userId)
        .then((response) => {
          if (response.data.error === true) {
            this.message = response.data.message
          } else {
            this.user = response.data.user
            this.user_accessories = response.data.user.user_accessories
            this.admin_scenario_accessories = response.data.admin_scenario_accessories
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/admin-scenario/accessory-users/store', { user_accessories: this.user_accessories, user_id: this.$route.params.user_id })
            .then((response) => {
              this.loader = false
              this.$notification.success({
                message: response.data.message,
              })
              this.$router.push({ name: 'adminScenarioAccessoriesUsers' })
            })
            .catch(error => {
              this.loader = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor {
  border: 1px solid red !important;
  border-radius: 4px !important;
}
</style>
